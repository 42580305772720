<template>
  <div class="sctp-bg-white">
    <div class=" sctp-pad15">
      <el-upload
        :http-request="fileUploadFunction"
        :data="{...uploadEnum.USER_AVATAR}"
        action=""
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="uploadSuccess">
        <el-image
          v-if="picture" :src="picture"
          style="width: 150px; height: 150px"
          fit="cover"></el-image>
        <el-image
          v-else :src="DEFAULT_AVATAR_PATH"
          style="width: 150px; height: 150px;border: 1px solid #eee;"
          fit="cover"></el-image>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，文件大小1M之内，建议200X200px</div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../../resources/js/config')
export default {
  name: 'avatar',
  data() {
    return {
      uploadEnum,
      picture: null, // 回显
      avatar: null   // 头像
    }
  },
  methods: {
    beforeUpload(file) {
      const isImage = file.type.startsWith('image')
      const isLtSize = file.size / 1024 / 1024 < 1
      if (!isImage) {
        this.$message.error('上传图片只能是 图片 格式!')
      }
      if (!isLtSize) {
        this.$message.error('上传图片大小不能超过 1M!')
      }
      return isImage && isLtSize && this.$message.info('正在上传') && true
    },
    uploadSuccess(res) {
      if (res.code) {
        const {src, preview} = res
        this.avatar = src;
        this.picture = preview;
        this.uploadAvatar();
      } else {
        this.$message.error('上传文件失败')
      }
    },
    uploadAvatar() {
      const {avatar} = this
      if (!avatar) {
        return
      }
      this.$request.post({
        reqcode: '1047',
        reqdesc: '修改用户头像',
        userId: this.user.userId,
        avatar: this.avatar
      }).then(res => {
        this.$message.success('修改成功')
        this.getAvatar();
      })
    },
    getAvatar() {
      this.$request.post({
        reqcode: '1046',
        reqdesc: '获取用户头像',
        userId: this.user.userId
      }).then(res => {
        const {data} = res
        if (data) {
          this.picture = data
        }
      })
    }
  },
  beforeMount() {
    this.getAvatar()
  },
  computed: {}
}
</script>

<style scoped lang="scss">
</style>
